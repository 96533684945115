const categories = [
    "Anéis",
    "Anéis infantis - falange",
    "Correntes Masculinas",
    "Colares",
    "Conjuntos",
    "Pulseiras Masculinas",
    "Pulseiras Femininas",
    "Pulseiras Infantis",
    "Tornozeleiras",
    "Argolas",
    "Brincos",
    "Pingentes"
];
export default categories;